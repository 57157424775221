<template>
  <layout :tituloPagina="`Network | Routers | ${router.nombre} | Tablero`">
    <grafica-trafico-isp
      v-for="isp, index in isps"
      :key="'trafico-isp-'+isp.id"
      :isp="isp" :idRouter="idRouter"
      :colorTx="colorDinamico(index)"
      :colorRx="colorDinamico(index+1)"
      @refrescar-isps="cargarIsps()"
    ></grafica-trafico-isp>

    <hr>

    <grafica-trafico-lan
      v-for="lan,index in lans"
      :key="'trafico-lan-'+lan.id"
      :lan="lan" :idRouter="idRouter"
      :colorTx="colorDinamico(index)"
      :colorRx="colorDinamico(index+1)"
      @refrescar-lans="cargarLans()"
    ></grafica-trafico-lan>

    <div class="row">
      <div class="col-lg-6">
        <button @click="preguntaReiniciarRouter()"
          class="btn btn-info">
          <i class="mdi mdi-refresh"></i>
          Reiniciar router
        </button>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-secondary" @click="cerrar()">
          <i class="mdi mdi-chevron-left"></i>
          Atrás
        </button>
      </div>
    </div>
    <br>
  </layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import RouterSrv from '@/services/RouterSrv.js'
import IspRouterSrv from '@/services/IspRouterSrv.js'
import LanRouterSrv from '@/services/LanRouterSrv.js'

import GraficaTraficoIsp from './GraficaTraficoIsp.vue'
import GraficaTraficoLan from './GraficaTraficoLan.vue'
import Swal from 'sweetalert2'
export default {
  name: 'TableroRouter',
  components: { Layout, GraficaTraficoIsp, GraficaTraficoLan },
  data() {
    return {
      router: {
        id: null,
        nombre: '',
        ip: '',
        descripcion: '',
        usuario: '',
        clave: '',
        puerto_api: 8728,
        puerto_web_proxy: 8080,
        usuario_bloqueado: true,
        clave_bloqueada: true,
        puerto_api_bloqueado: true,
        puerto_web_proxy_bloqueado:true,
        latitud: 0,
        longitud: 0,
        id_sitio: null,
        ifs_sinc: 0,
        bs_sinc: 0,
        pv_sinc: 0,
        intentos_conexion_fallidos_api: 0,
        proximo_intento_conexion_api: null,
        dns1_sugerido: null,
        dns2_sugerido: null,
        registro_en_log : false,
        cantidad_cpes: {
          total: 0,
          sincronizados: 0,
          por_sincronizar: 0
        }
      },
      isps: [],
      lans: [],
    }
  },

  computed:{
    idRouter: function() {
      return parseInt( this.$route.params.id )
    }
  },

  created: function() {
    var self = this

    // Determinación del modo
    self.cargarRouter()
  },
  watch: {},
  methods: {
    atras: function() {
      this.$router.go(-1)
    },

    cargarRouter: function() {
      var self = this

      RouterSrv.routerJSON(self.idRouter).then(response => {
        let router = response.data
        Object.assign(self.router, router)

        self.cargarIsps()
        self.cargarLans()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el router'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarIsps: function() {
      var self = this,
        idRouter= self.idRouter

      IspRouterSrv.ispsJSON(idRouter, { con_interfaz: true }).then(response => {
        self.isps = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },

    cargarLans: function() {
      var self = this,
        idRouter= self.idRouter

      LanRouterSrv.lansJSON(idRouter, { con_interfaz: true }).then(response => {
        self.lans = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    colorDinamico: function(index) {
      var self = this
      let color = "";

      let colores = [
        '#32CBFD', '#20B2AA', '#4682B4', '#90EE90', '#708090',
        '#18CC93', '#A3E1D4', '#9ACD32', '#800080', '#B5B8CF',
        '#D2B48C', '#6A5ACD', '#FF6347', '#2E8B57', '#FA8072',
        '#EE82EE'
      ];

      if(colores[index] != undefined) color = colores[index]
      else {
        for(let c=0;c<6;c++){
          color = color + self.generarLetra()
        }
        color = "#"+color
      }

      return color
    },

    generarLetra: function(){
      var letras = ["A","B","C","D","E","F","0","1","2","3","4","5","6","7","8","9"];
      var numero = (Math.random()*15).toFixed(0);
      return letras[numero];
    },

    preguntaReiniciarRouter() {
      let self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Reiniciar router",
        html: '¿Estás seguro que deseas reiniciar el router?<br><br> Esta acción apagará y encenderá nuevamente el router',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          RouterSrv.reiniciar(self.router.id).then(repsonse => {
            swal.fire("Reinicio", "Se mandó la instrucción de reinicio", "success");
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudieron cargar el trafico de la interfaz'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }
  }
}
</script>

<style scoped>
.badge-transferencia {
  background-color: #1ACC93;
  color: white;
}

.badge-recepcion {
  background-color: #32CBFD;
  color: white;
}
</style>