<template>
  <div class="card">
    <div class="card-header justify-content-between d-flex align-items-center">
      <h4 class="card-title">
        Gráfica de consumo ISP para <strong>{{ isp.nombre }}</strong> de la interfaz <strong>{{ isp.interfaz.name }}</strong>
      </h4>
      <div>
        <a href="#" onclick="return false;" @click="cerrar()">
          <i class="mdi mdi-close text-dark"></i>
        </a>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-6 mb-3">
          <div class="input-group">
            <span class="input-group-text" title="Mostar datos">
              <span class="d-none d-sm-block">
                Mostar datos
              </span>
              <span class="d-block d-sm-none">
                Datos
              </span>
            </span>
            <select
              class="form-select"
              v-model="mostrar_tipo"
              ref="id_interfaz"
              v-on:click="dibujarGrafica()"
            >
              <option :value="1">Mostrar ambos</option>
              <option :value="2">Transferencia (tx)</option>
              <option :value="3">Recepción (rx)</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6 mb-3">
          <div class="input-group">
            <span class="input-group-text" title="Tráfico por">
              <span class="d-none d-sm-block">
                Tráfico por
              </span>
              <span class="d-block d-sm-none">
                Por
              </span>
            </span>
            <select
              class="form-select"
              v-model="tipo_trafico"
              ref="tipoTrafico"
              v-on:click="cargarTrafico()"
            >
              <option value="hora">Hora</option>
              <option value="dia">Día</option>
              <option value="semana">Semana</option>
              <option value="mes">Mes</option>
              <option value="trimestre">Trimestre</option>
              <option value="anio">Año</option>
            </select>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-6 mt-2 text-center mb-3">
          <span
            class="badge font-size-14 text-white me-2 mb-2"
            :style="'background-color:'+colorTx+' !important;'"
            title="Transferencia (tx)"
            v-if="mostrar_tipo == 2 || mostrar_tipo == 1"
          >
            <span class="d-none d-sm-block">
              Transferencia (tx)
            </span>
            <span class="d-block d-sm-none">
              Tx
            </span>
          </span>
          <span
            class="badge font-size-14 text-white"
            :style="'background-color:'+colorRx+' !important;'"
            title="Recepción (rx)"
            v-if="mostrar_tipo == 3 || mostrar_tipo == 1"
          >
            <span class="d-none d-sm-block">
              Recepción (rx)
            </span>
            <span class="d-block d-sm-none">
              Rx
            </span>
          </span>
        </div>
        <div class="col-lg-3 col-md-6 col-6 mb-3 text-right">
          <button
            class="btn btn-light"
            @click="reiniciarTraficoIsp(isp)"
            :disabled="isp.reiniciar_trafico"
          >
            <span class="d-none d-sm-block">
              <i class="mdi mdi-cloud-sync"></i>
              Reiniciar tráfico
            </span>
            <span class="d-block d-sm-none">
              <i class="mdi mdi-cloud-sync"></i>
              Reiniciar
            </span>
          </button>
        </div>
      </div>

      <br>

      <apexchart
        ref="traficoIsp"
        class="apex-charts"
        height="380"
        dir="ltr"
        :series="series"
        :options="chartOptions"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import IspRouterSrv from '@/services/trafico/IspRouterSrv.js'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
  name: 'GraficaTraficoIsp',
  props: ['isp', "idRouter", "colorTx", "colorRx"],
  data() {
    return {
      tipo_trafico: 'hora',

      traficos: [],
      mostrar_tipo: 1,
      series: [
        {
          name: "",
          data: [],
        }
      ],
      // configuracion de la grafica
      chartOptions: {
        chart: {
          height: 360,
          type: "area",
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: true,
          },
        },
        markers: {
          size: 0,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#18cc93","#32cbfd"],
        title: {
          text: "",
          align: "left",
          style: {
            fontWeight: 500,
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: [],
          tickAmount: 9,
          tooltip: {
            enabled: false,
          }
        }
      },
      intervals: {
        actualizacion: null
      }
    }
  },
  created: function() {
    var self = this

    this.cargarTrafico()

    self.intervals.actualizacion = setInterval(function() {
      if(self.isp.id != null && self.idRouter != null) self.cargarTrafico()
    }, 300000)
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    cargarTrafico: function() {
      var self = this,
          params = {
            id_router: self.idRouter,
            tipo_trafico: self.tipo_trafico
          }

      self.traficos = []

      IspRouterSrv.traficoJSON(self.isp.id, params).then(response => {
        let traficos = response.data

        traficos.forEach(function(t) {
          let formato='',           formato2=''
          // 'YYYY-MM-DD HH:mm:ss' 'DD/MM/YYYY'
          switch(self.tipo_trafico) {
            case 'hora':
              formato = 'HH:mm'
              formato2 = 'HH:mm'
            break;
            case 'dia':
           
              formato = 'DD HH:mm'
              formato2 = 'ddd D[-]HH:mm [hrs]'
            break;
            case 'semana':
              formato = 'DD HH'
              formato2 = 'ddd D[-]HH [hrs]'
            break;
            case 'mes':
              formato = 'YYYY-MM-DD'
              formato2 = 'DD/MM/YYYY'
            break;
            case 'trimestre':
              formato = 'YYYY-MM-DD'
              formato2 = 'DD/MM/YYYY'
            break;
            case 'anio':
              formato = 'YYYY-MM-DD'
              formato2 = 'DD/MM/YYYY'
            break;
          }
          Object.assign(t,{time: self.formatoHora(t.tiempo, formato, formato2)})
          self.traficos.push(t)
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar el trafico de la interfaz'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
       self.dibujarGrafica()
      })
    },

    formatoHora(tiempo, formato='HH:mm', formato2='HH:mm:ss') {
      return moment(tiempo, formato).locale('es-mx').format(formato2)
    },

    dibujarGrafica(t) {
      var self = this

      let tx = []
      let rx = []
      let labels = []

      self.traficos.forEach((trafico) => {
        // tx.push((trafico.sum_tx_bytes*0.000001).toFixed(3))
        // rx.push((trafico.sum_rx_bytes*0.000001).toFixed(3))
        tx.push((trafico.tx_bytes*0.000001).toFixed(3))
        rx.push((trafico.rx_bytes*0.000001).toFixed(3))
        labels.push(trafico.time)
      })

      var series= []
      var colors= []

      switch (self.mostrar_tipo) {
        case 1:
          series = [
            {
              name: "Transferencia (tx)",
              data: tx
            },{
              name: 'Recepción (rx)',
              data: rx
            }
          ]
          colors = [self.colorTx, self.colorRx]
        break;
        case 2:
          series = [
            {
              name: 'Transferencia (tx)',
              data: tx
            }
          ]
          colors = [self.colorTx]
        break;
        case 3:
          series = [
            {
              name: 'Recepción (rx)',
              data: rx
            }
          ]
          colors = [ self.colorRx ]
        break;
      }

      self.$refs.traficoIsp.updateSeries(series, false)

      self.$refs.traficoIsp.updateOptions({
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: true,
          },
        },
        markers: {
          size: 0,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: colors,
        title: {
          text: "Consumo en tiempo real",
          align: "left",
          style: {
            fontWeight: 500,
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: labels,
          tickAmount: 10,
          tooltip: {
            enabled: false,
          }
        }
      })
    },

    reiniciarTraficoIsp(isp) {
      var self = this,
          params = { id_router: self.idRouter }

      if(self.isp.reiniciar_trafico) {
        iu.msg.warning("No se puede reiniciar el tráfico ISP, ya que se encuentra reiniciando el tráfico ISP")
        return
      }

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Reiniciar el tráfico ISP",
        html: '¿Está seguro que desea reiniciar el tráfico ISP para <strong>'+isp.nombre +'</strong>? <br><br>'+
              'Esto reiniciará todo el tráfico por hora, día, semana, mes, trimestre y año. <br><br>'+
              'Eliminando todo el tráfico registrado actualmente.',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          IspRouterSrv.reiniciarTrafico(self.isp.id, params).then(response => {
            swal.fire("Reiniciado!", "Se ha reiniciado el tráfico correctamente del ISP "+isp.nombre, "success");
            
            self.$emit('refrescar-isps')
            self.cargarTrafico()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudio reiniciar el tráfico de la interfaz'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }
  }
}
</script>

<style scoped>
</style>