import axios from 'axios'
import { APITRAFIC as API } from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/routers/isps`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  traficoJSON(idIsp, params) {
    params = params || {}
    return apiClient.get(`${idIsp}/traficos.json`, { params: params })
  },

  reiniciarTrafico(idIsp, params) {
    params = params || {}
    return apiClient.delete(`${idIsp}/reiniciar`, { params: params })
  }
}